import React, { useState } from "react";
import { useViewport } from "../ViewportProvider";
// Components
import BehaviourDaytimeDayView from "../BehaviourDaytimeDayView";
import BehaviourRange from "../BehaviourRange";
import HowTheBehaviourWorks from "../HowTheBehaviourWorks";
import MovementComparisonModal from "../MovementComparisonModal";
import { DAYTTIME_QUESTIONS_AND_ANSWERS } from "./howItWorks";
// Hooks
import { useFade } from "../../Services/Hooks";
// Utils
import { differenceInHours, isToday, subHours } from "date-fns";
import {
  dayTimeDayExpectedDuration,
  dayTimeDayFirstMovement,
  dayTimeDayMostRecent,
  dayTimeDayNoRecentActivity,
  learnMore,
  movementRangeComparison,
  getTimeOutsideDuringTheDay,
} from "../../Services/Utils/Insights";

const BehaviourDayTime = ({
  daytimeMovementData,
  movementV3Data,
  previousDataPeriod,
  independenceData,
  independenceDataPrevious,
  dateType,
  from,
  to,
  prevEndDate,
  prevStartDate,
  activeTab,
  changePage,
  serviceUser,
}) => {
  const { width } = useViewport();
  const onMobileView = width < 900;
  const filtersToRender = [
    {
      key: "dayTimeActivity",
      value: "Day-time activity",
      relatedBehaviour: "day-time",
    },
    {
      key: "below",
      value: "Below expected movement",
      relatedBehaviour: "day-time",
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filtersToRender[0]);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150);

  const learnMoreInsight = learnMore(() => setShowLearnMore(true));

  // Generate the required insights
  const rangeComparison = movementRangeComparison(
    movementV3Data,
    dateType,
    previousDataPeriod,
    prevStartDate,
    prevEndDate,
    from,
    to,
    "day-time",
    () => setShowAnimation(true)
  );

  const daysMovementData = movementV3Data[0];
  const isCurrentDay = isToday(to);

  const dayInsights = [];

  const lastInsightRiskLevel =
    daysMovementData?.insights?.[daysMovementData?.insights?.length - 1]
      ?.riskScore;

  const isModelReady =
    daysMovementData?.day?.insights?.[
      daysMovementData?.day?.insights?.length - 1
    ]?.modelReady;

  if (lastInsightRiskLevel === 2 && isModelReady) {
    const lastMovementTime =
      daysMovementData?.day?.lastEvent?.[1] &&
      new Date(daysMovementData?.day?.lastEvent?.[1]);

    const comparisonDate = isCurrentDay ? new Date() : subHours(to, 2);

    const numHoursWithNoActivity = differenceInHours(
      comparisonDate,
      lastMovementTime
    );
    const insight4 = dayTimeDayNoRecentActivity(
      numHoursWithNoActivity,
      isCurrentDay,
      serviceUser?.userstring
    );
    dayInsights.push(insight4);
  }

  const insight1 = dayTimeDayExpectedDuration(
    daysMovementData,
    serviceUser?.userstring,
    isCurrentDay,
    null
  );

  dayInsights.push(insight1);

  const insight2 = dayTimeDayFirstMovement(daysMovementData);
  dayInsights.push(insight2);

  if (isCurrentDay) {
    const insight3 = dayTimeDayMostRecent(daysMovementData);
    dayInsights.push(insight3);
  }

  // time outside during the day
  if (dateType === "day") {
    const timeOutsideDuringTheDay = getTimeOutsideDuringTheDay(
      independenceData,
      serviceUser?.serviceUserProperties?.forenames || serviceUser?.userstring
    );
    dayInsights.push(timeOutsideDuringTheDay);
  }
  dayInsights.push(learnMoreInsight);

  const rangeViewInsights = [rangeComparison, learnMoreInsight];

  if (dateType === "day") {
    return (
      <BehaviourDaytimeDayView
        daytimeMovementData={daytimeMovementData}
        movementV3Data={movementV3Data}
        howItWorks={DAYTTIME_QUESTIONS_AND_ANSWERS}
        insights={dayInsights}
        showLearnMore={showLearnMore}
        setShowLearnMore={setShowLearnMore}
      />
    );
  } else {
    return (
      <>
        {!showLearnMore && (
          <BehaviourRange
            data={movementV3Data}
            from={from}
            to={to}
            prevEndDate={prevEndDate}
            prevStartDate={prevStartDate}
            activeTab={activeTab}
            filtersToRender={filtersToRender}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            changePage={changePage}
            isMobile={onMobileView}
            dateType={dateType}
            howItWorks={DAYTTIME_QUESTIONS_AND_ANSWERS}
            insights={rangeViewInsights}
          />
        )}
        {showLearnMore && (
          <HowTheBehaviourWorks
            behaviour="Day-time"
            questionsAndAnswers={DAYTTIME_QUESTIONS_AND_ANSWERS}
            hide={() => setShowLearnMore(false)}
          />
        )}
        {isVisible && (
          <MovementComparisonModal
            hide={() => {
              setShowAnimation(false);
            }}
            showAnimation={showAnimation}
            dateType={dateType}
            currentData={rangeComparison.currentDataModal}
            previousData={rangeComparison.previousDataModal}
            behaviourName={"Day-time movement"}
          />
        )}
      </>
    );
  }
};

export default BehaviourDayTime;
