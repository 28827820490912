import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
// Hooks
import { useTimer, useFade, useGetSensorStatus } from "../../../Services/Hooks";
// Components
import SensorCard from "../../../Components/InstallationNew/SensorCard";
import SensorsHeader from "../../../Components/InstallationNew/SensorsHeader";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallQRLoading from "../../../Components/InstallQRLoading";
import NewStyleModal from "../../../Components/NewStyleModal";
import MobileDrawer from "../../../Components/MobileDrawer";
import SensorGuide from "../../../Components/InstallationNew/SensorGuide";
// Icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";
// Config
import { getInitials } from "@intelligentlilli/lilli-utils";
// Constants
import { INSTALL_INSTRUCTIONS } from "../../../Services/Constants";

const InstallHubProvisioning = ({ goBackLink, baseURL }) => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const { width } = useViewport();
  const isDesktop = width >= 900;
  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const serviceUser = serviceUsers.find((i) => i.id === parseInt(id));
  const serviceUserInitials = getInitials(serviceUser?.userstring) || "";
  const [stage, setStage] = useState("start");
  // We wait for the hub to become provisioned
  const { minutes, seconds, setMinutes } = useTimer({
    initialMinutes: 0,
    initialSeconds: 0,
  });
  const timesUp = minutes === 0 && seconds === 0;

  // Trigger the sensor status fetching
  const { hubIsOnline, loading, sensors, error } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: !timesUp,
  });

  // Installation guide
  const [isGuideVisible, setShowGuideAnimation, showGuideAnimation] = useFade(
    false,
    150
  );
  const instructions = INSTALL_INSTRUCTIONS["h1"];
  const isShowingSplashScreen = hubIsOnline || (timesUp && !hubIsOnline);
  return (
    <>
      <InstallationCard style={styles.card}>
        <div className={styles.card_top}>
          <div className={styles.desktop_right}>
            {!isDesktop && (!isShowingSplashScreen || stage === "start") && (
              <SensorsHeader
                isHub={true}
                hubIsOnline={hubIsOnline}
                loading={stage === "connecting"}
              />
            )}
            {stage === "start" ? (
              <SensorCard
                error={error}
                sensor={sensors?.devices?.[0]} // this is the hub
                sensors={sensors?.devices}
                showMissingLocationMessage={false}
                sensorLocations={null}
                loading={loading}
                onConnect={() => {
                  setMinutes(6);
                  setStage("connecting");
                }}
                onViewGuide={() => {
                  setShowGuideAnimation(true);
                }}
              />
            ) : (
              <>
                {!hubIsOnline && !timesUp && (
                  <InstallQRLoading initials={serviceUserInitials} />
                )}
                {hubIsOnline && (
                  <InstallationSplash
                    icon={<ThumbsUpIcon color={theme.expected2} />}
                    headers={["Great!", "Your Lilli hub is connected"]}
                    message={"Let's get your sensors installed"}
                    goBackButtonLabel={"Exit install"}
                    goBackOnClick={() => navigate(goBackLink)}
                    nextButtonLabel={"Continue"}
                    nextButtonOnClick={() =>
                      navigate(`${baseURL}/sensors/${id}/${devicestring}`)
                    }
                    isDesktop={isDesktop}
                  />
                )}
                {timesUp && !hubIsOnline && (
                  <InstallationSplash
                    icon={<ThumbsDownIcon color={theme.risk3} />}
                    headers={["Uh-oh!", "We could not connect your Lilli hub"]}
                    message={
                      "Let's try again, but if the problem continues, contact "
                    }
                    email={"support@intelligentlilli.com"}
                    goBackButtonLabel={"Exit install"}
                    goBackOnClick={() => navigate(goBackLink)}
                    nextButtonLabel={"Try again"}
                    nextButtonOnClick={() => setMinutes(6)}
                    isDesktop={isDesktop}
                  />
                )}
              </>
            )}
          </div>
          <div className={styles.desktop_left}>
            {isDesktop && (!isShowingSplashScreen || stage === "start") && (
              <SensorsHeader
                isHub={true}
                hubIsOnline={hubIsOnline}
                loading={stage === "connecting"}
              />
            )}
          </div>
        </div>
      </InstallationCard>
      {isGuideVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setShowGuideAnimation(false);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showGuideAnimation}
          size={"medium"}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={false}
            setShowGuideAnimation={setShowGuideAnimation}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setShowGuideAnimation(false);
          }}
          showGuide={showGuideAnimation}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={false}
            setShowGuideAnimation={setShowGuideAnimation}
          />
        </MobileDrawer>
      )}
    </>
  );
};

export default InstallHubProvisioning;
