import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import EventLogSquare from "../../Components/EventLogSquare";
import { useViewport } from "../../Components/ViewportProvider";
import { mobileBreakpoint } from "../../Services/config";
import { format } from "date-fns";

// HELPER FUNCTION
// Formats data for the event log: used on bathroom, and bedroom sensors
const getParsedDataForBuckets = (
  motionEvents,
  numberOfBuckets,
  isDesktopView,
  eventProperty
) => {
  // 1- Handles cases where we already have access to motion events and do not need to extract it with an additional property
  const extractedMotionEvents = eventProperty
    ? motionEvents?.[eventProperty]
    : motionEvents;
  // 2- If we are on desktopView, generate a larger number of buckets (i.e. 96 - 24 hours divided into 15 minute segments)
  // Otherwise, generate 24 buckets
  const parsedEvents = [];
  for (let i = 0; i < numberOfBuckets; i++) {
    parsedEvents.push([]);
  }
  // 3- Place timestamps into the correct bucket (either a 15 minute or 1-hour bucket)
  extractedMotionEvents?.forEach((time) => {
    // First find out at which index the time belongs
    const hours = parseInt(format(new Date(time), "HH"));
    const minutes = parseInt(format(new Date(time), "mm"));
    let index = 0;
    if (isDesktopView) {
      index = index + hours * 4 + Math.floor(minutes / 15);
    } else {
      index = hours;
    }
    parsedEvents[index].push(time);
  });

  return parsedEvents;
};

const EventLog = ({
  frontDoorEvents,
  movementEvents,
  bathroomEvents,
  bedroomEvents,
  fridgeEvents,
}) => {
  // Get the width of the users screen for responsive js
  const { width } = useViewport();
  const desktopView = width > mobileBreakpoint;
  // The number of buckets (squares) the event log renders depends on screen size. 15min buckets for mobile (24hrs * 4 = 96) and hourly buckets otherwise
  const numBuckets = desktopView ? 96 : 24;

  const [buckets, setBuckets] = useState([]);
  const [frontDoor, setFrontDoor] = useState([]);
  const [movement, setMovement] = useState([]);
  const [bathroom, setBathroom] = useState([]);
  const [bedroom, setBedroom] = useState([]);
  const [fridge, setFridge] = useState([]);
  const [closeTooltip, setCloseTooltip] = useState(false);

  // CONSTANTS
  const MOVEMENT_PROPERTY = "hall.wall.motion";
  // NOTE: you may be wondering why there is no "BEDROOM_PROPERTY = "bedroom.wall.motion", like we've done with the others above.
  // This is because the backend sends bedroom-analysis data without the "movementEvents" property.
  // As such, we do not need to do an extra "un-nesting" of bedroom-analysis data here. That was done inside the prepareBedroomData hook.

  // Create a list of the time buckets on mount
  useEffect(() => {
    // 1- Generate hourly time buckets
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    const buckets = [];
    // 2- Label the buckets
    hours.forEach((hour) => {
      // Add a "0" for hours less than 10 so the time is in the 03:XX format.
      const formattedHour = `${hour < 10 ? "0" : ""}${hour}`;
      // For larger screens each bucket is 15 mins
      const minutes = ["00", "15", "30", "45"];
      if (desktopView) {
        minutes.forEach((minute, index) => {
          buckets.push({
            label: `${formattedHour}:${minute}`,
            startTime: `${formattedHour}:${minute}`,
            endTime:
              index !== 3
                ? `${formattedHour}:${minutes[index + 1]}`
                : `${hour + 1 < 10 ? "0" : ""}${hour + 1}:00`,
          });
        });
      }
      // For smaller screens 1 hour buckets are used
      else {
        buckets.push({
          label: `${formattedHour}:00`,
          startTime: `${formattedHour}:00`,
          endTime: `${hour + 1 < 10 ? "0" : ""}${hour + 1}:00`,
        });
      }
    });
    setBuckets(buckets);
  }, [desktopView]);

  // When we get front door data create an array of the data in the required buckets
  useEffect(() => {
    if (frontDoorEvents) {
      const parsedFrontDoorEvents = [];
      for (let i = 0; i < numBuckets; i++) {
        parsedFrontDoorEvents.push([]);
      }
      frontDoorEvents.openedAt?.forEach((time) => {
        // First find out at which index the time belongs
        const hours = parseInt(format(new Date(time), "HH"));
        const minutes = parseInt(format(new Date(time), "mm"));
        let index = 0;
        if (desktopView) {
          index = index + hours * 4 + Math.floor(minutes / 15);
        } else {
          index = hours;
        }
        parsedFrontDoorEvents[index].push({ time: time, status: "opened" });
      });

      frontDoorEvents.closedAt?.forEach((time) => {
        // First find out at which index the time belongs
        const hours = parseInt(format(new Date(time), "HH"));
        const minutes = parseInt(format(new Date(time), "mm"));
        let index = 0;
        if (desktopView) {
          index = index + hours * 4 + Math.floor(minutes / 15);
        } else {
          index = hours;
        }
        parsedFrontDoorEvents[index].push({ time: time, status: "closed" });
      });
      setFrontDoor(parsedFrontDoorEvents);
    }
  }, [frontDoorEvents, desktopView, numBuckets]);

  // When we get FRIDGE door data create an array of the data in the required buckets
  useEffect(() => {
    if (fridgeEvents) {
      const parsedFridgeEvents = [];
      for (let i = 0; i < numBuckets; i++) {
        parsedFridgeEvents.push([]);
      }
      fridgeEvents.openedAt?.forEach((time) => {
        // First find out at which index the time belongs
        const hours = parseInt(format(new Date(time), "HH"));
        const minutes = parseInt(format(new Date(time), "mm"));
        let index = 0;
        if (desktopView) {
          index = index + hours * 4 + Math.floor(minutes / 15);
        } else {
          index = hours;
        }
        parsedFridgeEvents[index].push({ time: time, status: "opened" });
      });

      fridgeEvents.closedAt?.forEach((time) => {
        // First find out at which index the time belongs
        const hours = parseInt(format(new Date(time), "HH"));
        const minutes = parseInt(format(new Date(time), "mm"));
        let index = 0;
        if (desktopView) {
          index = index + hours * 4 + Math.floor(minutes / 15);
        } else {
          index = hours;
        }
        parsedFridgeEvents[index].push({ time: time, status: "closed" });
      });
      setFridge(parsedFridgeEvents);
    }
  }, [fridgeEvents, desktopView, numBuckets]);

  // When we get movement data create an array of the data in the required buckets
  useEffect(() => {
    if (movementEvents) {
      const parsedMovementEvents = getParsedDataForBuckets(
        movementEvents,
        numBuckets,
        desktopView,
        MOVEMENT_PROPERTY
      );
      setMovement(parsedMovementEvents);
    }
  }, [movementEvents, desktopView, numBuckets]);

  // When we get bathroom data create an array of the data in the required buckets
  useEffect(() => {
    if (bathroomEvents) {
      const parsedBathroomEvents = getParsedDataForBuckets(
        bathroomEvents,
        numBuckets,
        desktopView,
        false
      );
      setBathroom(parsedBathroomEvents);
    }
  }, [bathroomEvents, desktopView, numBuckets]);

  // When we get bedroom data create an array of the data in the required buckets
  useEffect(() => {
    if (bedroomEvents) {
      const parsedBedroomEvents = getParsedDataForBuckets(
        bedroomEvents,
        numBuckets,
        desktopView,
        false
      );
      setBedroom(parsedBedroomEvents);
    }
  }, [bedroomEvents, desktopView, numBuckets]);

  return (
    <div className={styles.event_log_container}>
      <h2>Event log</h2>
      <p>Tap or hover over each time block to see exact times of events</p>
      <div
        className={styles.event_log_log}
        onScroll={() => setCloseTooltip(true)}
      >
        <div className={styles.sensor_labels}>
          <div className={styles.sensor_name}></div>
          {frontDoorEvents && (
            <div className={styles.sensor_name}>Front door</div>
          )}
          {movementEvents && (
            <div className={styles.sensor_name}>Motion sensor</div>
          )}
          {fridgeEvents && (
            <div className={styles.sensor_name}>Fridge sensor</div>
          )}
          {bathroomEvents && (
            <div className={styles.sensor_name}>Bathroom sensor</div>
          )}
          {bedroomEvents && (
            <div className={styles.sensor_name}>Bedroom sensor</div>
          )}
        </div>

        <div className={styles.event_log_rows_container}>
          <div className={styles.events}>
            {buckets?.map((eventInterval, index) => (
              <div className={styles.label} key={index}>
                {index % (desktopView ? 4 : 2) === 0 && eventInterval.label}
              </div>
            ))}
          </div>
          {frontDoorEvents && (
            <div className={styles.events}>
              {frontDoor?.map((bucket, index) => (
                <EventLogSquare
                  key={index}
                  value={bucket.length}
                  events={bucket}
                  index={index}
                  entranceDoor
                  time={buckets[index]}
                  closeTooltip={closeTooltip}
                  setCloseTooltip={setCloseTooltip}
                />
              ))}
            </div>
          )}
          {movementEvents && (
            <div className={styles.events}>
              {movement?.map((bucket, index) => (
                <EventLogSquare
                  key={index}
                  value={bucket.length}
                  events={bucket}
                  index={index}
                  time={buckets[index]}
                  closeTooltip={closeTooltip}
                  setCloseTooltip={setCloseTooltip}
                />
              ))}
            </div>
          )}
          {fridgeEvents && (
            <div className={styles.events}>
              {fridge?.map((bucket, index) => (
                <EventLogSquare
                  key={index}
                  value={bucket.length}
                  events={bucket}
                  index={index}
                  entranceDoor
                  time={buckets[index]}
                  closeTooltip={closeTooltip}
                  setCloseTooltip={setCloseTooltip}
                />
              ))}
            </div>
          )}
          {bathroomEvents && (
            <div className={styles.events}>
              {bathroom?.map((bucket, index) => (
                <EventLogSquare
                  key={index}
                  value={bucket.length}
                  events={bucket}
                  index={index}
                  time={buckets[index]}
                  closeTooltip={closeTooltip}
                  setCloseTooltip={setCloseTooltip}
                />
              ))}
            </div>
          )}
          {bedroomEvents && (
            <div className={styles.events}>
              {bedroom?.map((bucket, index) => (
                <EventLogSquare
                  key={index}
                  value={bucket.length}
                  events={bucket}
                  index={index}
                  time={buckets[index]}
                  closeTooltip={closeTooltip}
                  setCloseTooltip={setCloseTooltip}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventLog;
