import styles from "./styles.module.scss";
import Page from "../../../Components/Page";

const InstallWrapper = ({ children, isWeb }) => {
  return isWeb ? (
    <Page className={styles.page}>{children}</Page>
  ) : (
    <div>{children}</div>
  );
};

export default InstallWrapper;
