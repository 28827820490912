import React, { useState } from "react";
import theme from "../../../Styles/theme.scss";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
// API
import { postHubProperty } from "@intelligentlilli/api-layer";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setRefreshOrganisationHubs,
  setRefreshUser,
} from "../../../State/slices/session-slice";
// Components
import CTAButton from "../../../Components/CTAButton";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
// services
import { mobileBreakpoint } from "../../../Services/config";
// hooks
import { useGetUserRole } from "../../../Services/Hooks";
// icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";
import { getServiceUserById } from "@intelligentlilli/lilli-utils";

const InstallFinish = ({ isWeb, baseURL, goBackLink }) => {
  const { id, devicestring } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();

  // sizing
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  const { userIsJustAnInstaller, userIsManagerOrAdmin } = useGetUserRole();
  console.log("userIsManagerOrAdmin?", userIsManagerOrAdmin);
  console.log("userIsJustAnInstaller?", userIsJustAnInstaller);

  // redux state
  const dispatch = useDispatch();

  const server = useSelector((state) => state.session.server);
  const sensorLocations = useSelector((state) => state.sensorLocations);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const user = getServiceUserById(serviceUsers, id);
  // Local state
  const [installNotes, setNotes] = useState();
  console.log("InstallFinish > sensorLocations:", sensorLocations);
  console.log("InstallFinish > installNotes:", installNotes);
  // back-up check that all postHubProperty requests were sent
  const [sentSensorLocations, setSentSensorLocations] = useState(false);
  const [sentInstallationNotes, setSentInstallationNotes] = useState(false);
  const [sentInstallationComplete, setSentInstallationComplete] =
    useState(false);
  const [stage, setStage] = useState("start");

  const allPostRequestsSuccessful =
    sentSensorLocations &&
    (installNotes ? sentInstallationNotes : true) &&
    sentInstallationComplete;

  const goToSensorsScreen = () => {
    navigate(`${baseURL}/sensors/${id}/${devicestring}`);
  };

  const completeInstallation = () => {
    dispatch(setLoading(true));
    // if the user refreshses on this screen, it will clear the sensor locations
    // when that happens, this will result in the 'Uh oh' Screen where the 'Try Again' button will
    // navigate the user back to the sensors screen
    if (!sensorLocations) {
      console.log("no sensor locations!");
      setSentSensorLocations(false);
      setSentInstallationNotes(false);
      setSentInstallationComplete(false);
      setStage("end");
      dispatch(setLoading(false));
      return;
    }

    postHubProperty(
      server,
      id,
      "SensorLocations",
      JSON.stringify(sensorLocations),
      "web"
    )
      .then((res) => {
        console.log("1- <Sensor locations> response:", res);
        console.log("1- Res.status:", res.status);
        console.log("1- Res.ok?:", res.ok);
        setSentSensorLocations(true);
        if (res.ok) {
          console.log("1- Res.Ok");
        } else {
          console.log("1- Res NOT ok");
        }
      })
      .catch((err) => {
        console.log("1- Sensor locations: there has been an error:", err);
        dispatch(setLoading(false));
      });

    if (installNotes) {
      console.log("completeInstallation installNotes:", installNotes);
      postHubProperty(server, id, "InstallationNotes", installNotes, "web")
        .then((res) => {
          console.log("2- Installation <Notes> response:", res);
          console.log("2- Res.status:", res.status);
          console.log("2- Res.ok?:", res.ok);
          setSentInstallationNotes(true);

          if (res.ok) {
            console.log("2- Res.Ok");
          } else {
            console.log("2- Res NOT ok");
          }
        })
        .catch((err) => {
          console.log("2- Installation Notes: there has been an error:", err);
          dispatch(setLoading(false));
        });
    }

    postHubProperty(server, id, "InstallationStatus", "complete", "web")
      .then((res) => {
        console.log("3- InstallationStatus <complete> response:", res);
        console.log("3- Res.status:", res.status);
        console.log("3- Res.ok?:", res.ok);
        setSentInstallationComplete(true);
        setStage("end");
        if (userIsManagerOrAdmin) {
          dispatch(setRefreshOrganisationHubs(true));
        } else {
          dispatch(setRefreshUser(true));
        }
        if (res.ok) {
          console.log("3- Res.Ok");
        } else {
          console.log("3- Res NOT ok");
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log("3- Installation Status: there has been an error:", err);
        dispatch(setLoading(false));
      });
  };

  return (
    <>
      <InstallationCard style={styles.card}>
        <div className={styles.card_right}>
          {stage === "start" && (
            <>
              <div data-private className={styles.top}>
                <div className={styles.instructions}>
                  <h2 className={styles.main_title}>Any notes?</h2>
                  <p>
                    Almost there! Use the space below to add any notes you would
                    like about this install.
                  </p>
                </div>
                <label>
                  Install notes
                  <textarea
                    value={installNotes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="e.g. Service user stays at family home on Thursdays..."
                    className={styles.text_area}
                  />
                </label>
              </div>
              <div className={styles.buttons}>
                <div>
                  <InstallationGoBackButton
                    textColor={theme.primary6}
                    onClick={() => navigate(-1)}
                    label={"Exit Install"}
                  />
                </div>
                <div>
                  <CTAButton
                    style={{ width: "100%" }}
                    onClick={completeInstallation}
                  >
                    Complete
                  </CTAButton>
                </div>
              </div>
            </>
          )}
          {stage === "end" && allPostRequestsSuccessful && (
            <InstallationSplash
              icon={<ThumbsUpIcon color={theme.expected2} />}
              headers={["Install complete!"]}
              message={
                "You’ve completed the installation. This user is now ready to be monitored."
              }
              installSUCard={
                <div className={styles.install_card}>
                  <div>
                    <p className={styles.header}>Lilli user</p>
                    <p className={styles.text}>{user?.userstring}</p>
                  </div>
                  <div>
                    <p className={styles.header}>Kit installed</p>
                    <p className={styles.text}>{devicestring}</p>
                  </div>
                </div>
              }
              installNotes={
                sentInstallationNotes ? (
                  <div className={styles.install_card}>
                    <div>
                      <p className={styles.header}>Installation notes</p>
                      <p>{installNotes}</p>
                    </div>
                  </div>
                ) : null
              }
              email={userIsJustAnInstaller && "support@intelligentlilli.com"}
              isDesktop={isDesktop}
              nextButtonLabel={
                isWeb && userIsJustAnInstaller ? "Start another install" : null
              }
              nextButtonOnClick={
                isWeb && userIsJustAnInstaller
                  ? () => navigate("/install")
                  : null
              }
            />
          )}
          {stage === "end" && !allPostRequestsSuccessful && (
            <InstallationSplash
              icon={<ThumbsDownIcon color={theme.risk3} />}
              headers={["Install failed"]}
              message={
                "Let's try that again, but if the problem continues, please contact "
              }
              email={"support@intelligentlilli.com"}
              nextButtonLabel={"Try again"}
              nextButtonOnClick={goToSensorsScreen}
              isDesktop={isDesktop}
              goBackOnClick={() => navigate(goBackLink)}
              goBackButtonLabel={"Exit install"}
            />
          )}
        </div>
      </InstallationCard>
    </>
  );
};

export default InstallFinish;
