import React, { useState } from "react";
import { useViewport } from "../ViewportProvider";
// Components
import BehaviourOverviewDay from "../BehaviourOverviewDay";
import BehaviourRange from "../BehaviourRange";
import { getEarlyHoursEvents } from "../../Services/Utils/EventLog";
import {
  bathroomDayVisits,
  dayTimeDayExpectedDuration,
  dayTimeDayFirstMovement,
  dayTimeDayNoRecentActivity,
  nighttimeDoorEvents,
  getTimeOutsideDuringTheDay,
  nightTimeDayExpectedDuration,
  nightTimeDayLastMovementHour,
  nighttimeRangeDisturbances,
  sustenanceDayMissedMeals,
  sustenanceDayDaytimeEvents,
  sustenanceDayNighttimeEvents,
  sustenanceRangeMissedMeals,
  temperatureDayAverage,
  getTimeOutsideAtNight,
} from "../../Services/Utils/Insights";
import {
  addHours,
  startOfDay,
  differenceInHours,
  subHours,
  isToday,
} from "date-fns";
import { filtersToRender } from "./constants";

const BehaviourOverview = ({
  movementV3Data,
  sustenanceData,
  sustenanceDataPrevious,
  independenceData,
  independenceDataPrevious,
  temperatureData,
  oneDayOfTemperatureData,
  bathroomInference,
  previousDataPeriod,
  events,
  dateType,
  from,
  to,
  prevEndDate,
  prevStartDate,
  changePage,
  serviceUser,
}) => {
  const { width } = useViewport();
  const onMobileView = width < 900;

  // Overview Range View Filters
  const [selectedFilter, setSelectedFilter] = useState(filtersToRender[0]);

  const getBehaviourDataForSelectedFilter = (selectedFilter) => {
    switch (selectedFilter.relatedBehaviour) {
      case "day-time":
        return movementV3Data;
      case "night-time":
        return movementV3Data;
      case "independence":
        return independenceData;
      case "temperature":
        return temperatureData;
      case "bathroom":
        return bathroomInference;
      case "sustenance":
        return sustenanceData;
      default:
        return [];
    }
  };

  // Function to handle insight click
  const navigateToBehaviourDay = (tab) => {
    changePage({ tab: tab }, to, to, "day");
  };

  const daysMovementData = movementV3Data[0];
  const noMovementData =
    daysMovementData?.durationMinutes?.dayTime >= 0 ? false : true;
  const isCurrentDay = isToday(to);

  const dayViewInsights = [];

  const lastInsightRiskLevel =
    daysMovementData?.insights?.[daysMovementData?.insights?.length - 1]
      ?.riskScore;

  const isModelReady =
    daysMovementData?.day?.insights?.[
      daysMovementData?.day?.insights?.length - 1
    ]?.modelReady;

  if (dateType === "day" && lastInsightRiskLevel === 2 && isModelReady) {
    const lastMovementTime =
      daysMovementData?.day?.lastEvent?.[1] &&
      new Date(daysMovementData?.day?.lastEvent?.[1]);

    const comparisonDate = isCurrentDay ? new Date() : subHours(to, 2);

    const numHoursWithNoActivity = differenceInHours(
      comparisonDate,
      lastMovementTime
    );
    const insight0 = dayTimeDayNoRecentActivity(
      numHoursWithNoActivity,
      isCurrentDay,
      serviceUser?.userstring
    );
    dayViewInsights.push(insight0);
  }

  if (dateType === "day") {
    // nighttime expected duration
    const insight1 = nightTimeDayExpectedDuration(
      daysMovementData,
      serviceUser?.userstring,
      () => navigateToBehaviourDay("night-time"),
      noMovementData
    );
    dayViewInsights.push(insight1);
    // daytime expected duration
    const insight2 = dayTimeDayExpectedDuration(
      daysMovementData,
      serviceUser?.userstring,
      isCurrentDay,
      () => navigateToBehaviourDay("day-time"),
      noMovementData
    );
    dayViewInsights.push(insight2);
    // door events at night
    const morningStart = addHours(startOfDay(new Date(to)), 6);
    const earlyHoursEvents = getEarlyHoursEvents(events, morningStart);
    const insight3 = nighttimeDoorEvents(earlyHoursEvents, to);
    dayViewInsights.push(insight3);
    // eating and drinking at night
    const insight9 = sustenanceDayNighttimeEvents(
      sustenanceData,
      sustenanceDataPrevious,
      () => {
        navigateToBehaviourDay("sustenance");
      }
    );
    dayViewInsights.push(insight9);
    // time outside at night
    const insight4 = getTimeOutsideAtNight(
      independenceData,
      independenceDataPrevious,
      serviceUser?.serviceUserProperties?.forenames || serviceUser?.userstring
    );
    dayViewInsights.push(insight4);
    // first movement of the day
    const insight7 = dayTimeDayFirstMovement(daysMovementData);
    dayViewInsights.push(insight7);
    // time outside during the day
    const insight5 = getTimeOutsideDuringTheDay(
      independenceData,
      serviceUser?.serviceUserProperties?.forenames || serviceUser?.userstring
    );
    dayViewInsights.push(insight5);
    // eating and drinking during the day
    const insight8 = sustenanceDayDaytimeEvents(sustenanceData, () => {
      navigateToBehaviourDay("sustenance");
    });
    dayViewInsights.push(insight8);
    // missed meals
    const insight10 = sustenanceDayMissedMeals(sustenanceData, () => {
      navigateToBehaviourDay("sustenance");
    });
    dayViewInsights.push(insight10);
    // bathroom
    const insight11 = bathroomDayVisits(bathroomInference, "Bathroom", () => {
      navigateToBehaviourDay("bathroom");
    });
    dayViewInsights.push(insight11);

    // wind down
    if (!isCurrentDay) {
      const insight12 = nightTimeDayLastMovementHour(daysMovementData);
      dayViewInsights.push(insight12);
    }

    // temperature
    const insight13 = temperatureDayAverage(oneDayOfTemperatureData, () => {
      navigateToBehaviourDay("temperature");
    });
    dayViewInsights.push(insight13);
  }

  // Range view insights
  const rangeInsight1 = nighttimeRangeDisturbances(movementV3Data, () =>
    changePage({ tab: "night-time" })
  );
  const rangeInsight2 = sustenanceRangeMissedMeals(sustenanceData, () => {
    changePage({ tab: "sustenance" });
  });
  const rangeInsights = [rangeInsight1, rangeInsight2];

  return dateType === "day" ? (
    <BehaviourOverviewDay events={events} insights={dayViewInsights} />
  ) : (
    <BehaviourRange
      data={getBehaviourDataForSelectedFilter(selectedFilter)}
      from={from}
      to={to}
      prevEndDate={prevEndDate}
      prevStartDate={prevStartDate}
      previousDataPeriod={previousDataPeriod}
      // the changePage function accepts the activeTab only as an object, which we need to construct
      activeTab={{ tab: selectedFilter.relatedBehaviour }}
      filtersToRender={filtersToRender}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      changePage={changePage}
      isMobile={onMobileView}
      dateType={dateType}
      insights={rangeInsights}
    />
  );
};

export default BehaviourOverview;
