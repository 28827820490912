import React from "react";
import theme from "../../theme.scss";

export const ThumbsDownIcon = ({ width, color }) => {
  return (
    <svg
      width={width || 54}
      height={1.12 * width || 58}
      viewBox="0 0 55 49"
      id="ThumbsDownIcon"
    >
      <g transform="rotate(180, 27.5, 27.5)">
        <path
          d="M54.8877 26.8125L53.2721 38.2708C52.875 40.9981 51.5127 43.4924 49.4328 45.3006C47.353 47.1088 44.6935 48.111 41.9375 48.125L11.4583 48.125C8.42051 48.1214 5.50815 46.913 3.36009 44.7649C1.21202 42.6168 0.00363958 39.7045 1.00172e-06 36.6667L2.00344e-06 25.2083C0.00364111 22.1705 1.21202 19.2582 3.36009 17.1101C5.50816 14.962 8.42051 13.7536 11.4583 13.75L17.6733 13.75L22.0596 4.86292C22.797 3.3651 24.0482 2.18224 25.585 1.53009C27.1219 0.87794 28.8419 0.799956 30.4315 1.31036C32.0211 1.82076 33.3742 2.88554 34.2441 4.31049C35.114 5.73543 35.4427 7.4256 35.1702 9.07271L34.4002 13.75L43.5417 13.75C45.182 13.7499 46.8032 14.102 48.2957 14.7825C49.7882 15.463 51.1172 16.456 52.1929 17.6944C53.2686 18.9328 54.0658 20.3877 54.5307 21.9607C54.9956 23.5338 55.1174 25.1883 54.8877 26.8125ZM11.4583 43.5417L16.0417 43.5417L16.0417 18.3333L11.4583 18.3333C9.63497 18.3333 7.88629 19.0577 6.59698 20.347C5.30766 21.6363 4.58334 23.385 4.58334 25.2083L4.58333 36.6667C4.58333 38.49 5.30766 40.2387 6.59698 41.528C7.88629 42.8173 9.63497 43.5417 11.4583 43.5417ZM48.73 20.6983C48.0847 19.9559 47.2876 19.3606 46.3926 18.9526C45.4975 18.5446 44.5253 18.3334 43.5417 18.3333L31.7006 18.3333C31.3682 18.3334 31.0398 18.2612 30.7381 18.1216C30.4364 17.9821 30.1687 17.7786 29.9535 17.5253C29.7383 17.2719 29.5808 16.9748 29.4919 16.6545C29.4031 16.3342 29.3849 15.9984 29.4388 15.6704L30.6488 8.33708C30.7069 7.9967 30.6891 7.64768 30.5968 7.31493C30.5045 6.98218 30.3399 6.6739 30.1148 6.41209C29.8508 6.12749 29.523 5.90963 29.1584 5.77645C28.7938 5.64326 28.4027 5.59854 28.0174 5.64598C27.6322 5.69341 27.2637 5.83163 26.9422 6.04928C26.6208 6.26693 26.3556 6.55779 26.1685 6.89792L21.1521 17.0546C21.0198 17.3086 20.841 17.5356 20.625 17.7237L20.625 43.5417L41.9375 43.5417C43.5927 43.5356 45.1906 42.935 46.4399 41.8493C47.6893 40.7636 48.5069 39.265 48.7438 37.6269L50.3617 26.1685C50.4974 25.1931 50.4219 24.1999 50.1404 23.2561C49.8589 22.3123 49.3779 21.4401 48.73 20.6983Z"
          fill={color || theme.primary6}
        />
      </g>
    </svg>
  );
};
